<template>
  <main class="login">
    <div v-if="isConfirm">
      <forgetpassword @clicked="loginScreen" :loginName="username"></forgetpassword> 
    </div>
    <div v-else>
      <h1 class="title login__title">Log-in</h1>
      <div class="login__enter">
        <h3 class="title title__h3 text-center w-100">{{ $t("Login") }}</h3>
        <p class="text login__text text-center w-100">{{ $t("Welcome, please enter a username and password to log in") }}</p>
        <form @submit.prevent="handleSubmit" class="authorisation login__authorisation">
          <div class="authorisation__name">
            <label class="label" for="username">{{ $t("Username") }}</label>
            <input
              id="username"
              v-model="username"
              v-model.lazy="$v.username.$model"
              class="input authorisation__input"
              :class="{ 'validation-error': $v.username.$error }"
              type="text"
              @keyup.enter="onEnter('uname')"
            />
          </div>
          <div class="authorisation__password">
            <label class="label" for="password">{{ $t("Password") }}</label>
            <input
              id="password"
              v-model="password"
              v-model.lazy="$v.password.$model"
              class="input authorisation__input"
              :class="{ 'validation-error': $v.password.$error }"
              type="password"
              @keyup.enter="onEnter('upass')"
            />
          </div>
          <div class="authorisation__remember">
          
            <a
              class="link authorisation__forgot"
              @click="changeConfirm">
              {{ $t("Forgot Password") }}</a>
          </div>
          <a
            class="link authorisation__button color-white mt-15"
            @click="handleSubmit"
          >{{ $t("Login") }}</a>
          
        </form>
      </div>
      <div class="login-contact-block df-c fs16 weight-700 pointer" @click="openSupportModal">
        <div class="contact-btn df-c mx-5">
          <img src="/assets/img/user-headset-light.svg" alt="">
        </div>
        {{ $t("Contact Us") }}
      </div>
      <loading :active.sync="loggingIn" :can-cancel="false" :is-full-page="true"></loading>
    </div>
    <SupportPopup></SupportPopup>
    <Modal2FA ref="modal2FARef" userType="Admin"></Modal2FA>
  </main>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import DeviceDetector from "device-detector-js";
import forgetpassword from "./ForgetPassword";
import SupportPopup from "@/components/SupportPopup.vue";
import Modal2FA from "@/components/auth/Modal2FA.vue";

export default {
  name: "Login",
  mixins: [validationMixin],
  components: {
    Loading,
    forgetpassword,
    SupportPopup,
    Modal2FA
  },
  computed: {
    ...mapState({
      loggingIn: state => state.account.loggingIn,
      loggedIn: state => state.account.loggedIn
    })
  },
  data: () => {
    return {
      username: "",
      password: "",
      isConfirm: false,
    };
  },
  validations: {
    username: {
      required
    },
    password: {
      required
    }
  },
  created() {
    this.logout();
  },
  methods: {
    ...mapActions("account", ["login", "logout"]),
    ...mapActions("permission", {
      getUserPermissionsData: "getUserPermissionsData"
    }),
    async handleSubmit() {
      let that = this;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const deviceDetector = new DeviceDetector();
      const userAgent = window.navigator.userAgent;
      const device = deviceDetector.parse(userAgent); 
      const authData = {
        username: this.username,
        password: this.password,
        browser: device.client.name,
        os_name: device.os.name,
        os_version: device.os.version,
        os_platform: device.os.platform,
        device_type: device.device.type
      }

      this.login(authData).then(
        async result => {
          if (that.loggedIn) {
            localStorage.setItem("user_type_logged_in", "Reseller")
            try {
              await that.$router.push(that.$route.query.redirect || "/");
            } catch (e) {}
          } else if (result.message == "Check 2fa") {
            that.openModal2FA();
          }
          return result;
        },
        error => {
          that.password = "";
          return error;
        }
      );
    },
    changeConfirm() {
      this.isConfirm= true;
    },
    loginScreen(change) {
      this.isConfirm= change;
    },
    onEnter(formType) {
      if (formType == 'uname' && this.password == "") {
        document.getElementById("password").focus();
      } else if (formType == 'upass' && this.username == "") {
        document.getElementById("username").focus();
      }

      if (this.username != "" && this.password != "") {
        this.handleSubmit();
      }
    },
    openSupportModal() {
      this.$modal.show('supportPopup');
    },
    openModal2FA() {
      this.$refs.modal2FARef.openModal();
    },
  }
};
</script>

<style lang="scss" scoped>
.title__h3 {
  font-size: 40px !important;
}

.link {
  cursor: pointer;
}
.login__enter {
  height: auto;
  text-align: unset;
}

.authorisation {
  text-align: unset;
}
.login-contact-block {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  margin-right: 0;
  .contact-btn {
    background-color: #002036;
    width: 66px;
    height: 66px;
    border-radius: 50%;
    img {
      width: 32px;
    }
  }
}
</style>
